<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :disabled="loading"
            :counter="30"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-autocomplete
            v-if="user.is_superadmin"
            v-model="form.organization_id"
            :items="organizations"
            :disabled="loading"
            outlined
            dense
            clearable
            item-text="name"
            item-value="id"
            label="Organization"
            @click:clear="setPropertyToNull(form,'organization_id')"
          />

          <span>Observe</span>
          <v-row>
            <v-col class="pb-0">
              <v-autocomplete
                v-model="form.device_id"
                :rules="deviceRules"
                :items="devices"
                :disabled="loading"
                :loading="loadingDevices"
                label="Device"
                outlined
                dense
                item-value="id"
                item-text="name"
                @keypress.enter="saveItem"
              />
            </v-col>
            <v-col class="pb-0">
              <v-autocomplete
                v-model="form.payload_key"
                :rules="payloadKeyRules"
                :items="payloadKeys"
                :disabled="loading"
                label="Payload Key"
                outlined
                dense
                item-value="id"
                item-text="name"
                @keypress.enter="saveItem"
              />
            </v-col>
          </v-row>

          <span>Trigger When</span>
          <v-row>
            <v-col class="pb-0">
              <v-select
                v-model="form.operator"
                :rules="operatorRules"
                :items="operators"
                :disabled="loading"
                label="Operator"
                outlined
                dense
                @keypress.enter="saveItem"
              />
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                v-model="form.threshold_value"
                :rules="thresholdRules"
                :disabled="loading"
                label="Threshold Value"
                outlined
                dense
                @keypress.enter="saveItem"
              />
            </v-col>
          </v-row>
          <v-checkbox
            v-model="form.enable_logging"
            class="mt-0 pt-0"
            label="Enable Logging"
          />
          <v-checkbox
            v-model="form.enable_notification"
            class="mt-0 pt-0"
            label="Enable Notification"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { underscoreToSpace } from '../../utils/utils';
import FormMixin from '../../mixins/FormMixin';

export default {
  name: 'TriggerForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        device_id: null,
        payload_key: null,
        threshold_value: null,
        operator: null,
        enable_notification: null,
        enable_logging: null,
        organization_id: null,
      },
      defaultForm: {
        name: '',
        device_id: null,
        payload_key: null,
        threshold_value: null,
        operator: null,
        enable_notification: null,
        enable_logging: null,
        organization_id: null,
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      deviceRules: [
        (v) => !!v || 'Device is required',
      ],
      payloadKeyRules: [
        (v) => !!v || 'Payload Key is required',
      ],
      operatorRules: [
        (v) => !!v || 'Operator is required',
      ],
      thresholdRules: [
        (v) => !!v || 'Threshold is required',
      ],
      devices: [],
      payloadKeys: [],
      operators: [
        {
          value: 'equal',
          text: 'Equal (=)',
        },
        {
          value: 'above',
          text: 'Above (>)',
        },
        {
          value: 'below',
          text: 'Below (<)',
        },
      ],
      loadingDevices: false,
    };
  },

  computed: {
    ...mapGetters(['user', 'organizations']),
  },

  watch: {
    async dialog(val) {
      if (val) {
        await this.getDevices();
        this.getPayloadKeys(this.form.device_id);
      }
    },

    'form.device_id': function (deviceId) {
      // respond only when form opened
      if (this.dialog) {
        this.getPayloadKeys(deviceId);
        this.form.payload_key = this.item?.payload_key || null;
      }
    },
  },


  methods: {
    addData() {
      return this.$api.addTrigger(this.formData);
    },

    updateData() {
      return this.$api.updateTrigger({
        id: this.form.id,
        ...this.formData,
      });
    },

    async getDevices() {
      this.loadingDevices = true;
      try {
        const result = await this.$api.getDevices();
        this.devices = result.data.data;
      } catch (error) {
        console.error(error);
        this.$toast.error('Failed Get Devices');
      }
      this.loadingDevices = false;
    },

    getPayloadKeys(deviceId) {
      const device = this.devices.find((d) => d.id === deviceId);
      if (!device?.latest_payload) {
        this.payloadKeys = [];
      } else {
        this.payloadKeys = Object.keys(device.latest_payload)
          .filter((item) => item !== 'datetime')
          // map for select
          .map((item) => ({
            id: item,
            name: underscoreToSpace(item),
          }));
      }
    },
  },
};
</script>
