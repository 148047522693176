<template>
  <v-row>
    <v-col>
      <div class="mb-5">
        <v-row
          align="stretch"
          no-gutters
        >
          <v-col class="grow">
            <SearchKeyword
              :filter="filter"
              :searchable="['device name','device ID', 'device EUI']"
            />
          </v-col>
          <v-col class="shrink mt-1 ml-3">
            <TriggerListFilter :filter="filter" />
          </v-col>
        </v-row>
      </div>

      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-cog-sync</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <!-- name -->
          <template v-slot:item.name="{ item }">
            <span
              v-html="highlightString(item.name,routeQuery.keyword)"
            />
          </template>
          <!-- device -->
          <template v-slot:item.device="{ item }">
            <router-link
              :to="`/devices/${item.device_id}`"
              class="body-1 font-weight-bold no-underline"
              v-html="highlightString(`${item.device.name || item.device.device_id}`,routeQuery.keyword)"
            />
          </template>
          <!-- when -->
          <template v-slot:item.when="{ item }">
            <span>{{ item.payload_key }} {{ parseOperator(item.operator) }} {{ item.threshold_value }}</span>
          </template>
          <!-- organization -->
          <template v-slot:item.organization="{item}">
            <router-link
              v-if="item.organization"
              :to="`/organizations/${item.organization.id}`"
              class="body-1 font-weight-bold no-underline"
            >
              {{ item.organization.name }}
            </router-link>
          </template>
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click.stop="showInfo(item)"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <span>Info</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="openForm(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <TriggerForm
        ref="formModal"
        :item="item"
        @reload-data="getItem()"
      />

      <TriggerInfo
        ref="triggerInfo"
        :trigger-detail="triggerDetail"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchKeyword from '../../components/SearchKeyword.vue';
import TriggerInfo from './TriggerInfo.vue';
import DatalistMixin from '../../mixins/DatalistMixin';
import TriggerForm from './TriggerForm.vue';
import TriggerListFilter from './TriggerListFilter.vue';

export default {
  name: 'TriggerList',
  components: {
    TriggerListFilter, TriggerForm, TriggerInfo, SearchKeyword,
  },
  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '200px',
        },
        {
          text: 'Device',
          value: 'device',
        },
        {
          text: 'Trigger When',
          value: 'when',
        },
        {
          text: 'Organization',
          value: 'organization',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '170px',
        },
      ],
      filter: {
        page: 1,
        keyword: null,
        organization_id: null,
        device_id: null,
      },
      triggerDetail: {},
    };
  },

  computed: {
    ...mapGetters(['user']),

    isAdmin() {
      return this.user.is_superadmin || this.user.is_organization_admin;
    },
  },

  created() {
    this.$store.dispatch('getDevices');
    if (!this.user.is_superadmin) {
      // hide 'organization' header, when not superadmin
      this.headers = this.headers.filter((h) => h.value !== 'organization');
      // non admin cant use actions
      if (!this.isAdmin) {
        this.headers = this.headers.filter((h) => h.value !== 'actions');
      }
      // non superadmin cant filter by organization
      delete this.filter.organization_id;
    } else {
      // superadmin load all organizations
      this.$store.dispatch('getOrganizations');
    }
  },

  methods: {
    getData() {
      return this.$api.getTriggers({
        ...this.filter,
      });
    },

    deleteData(triggerId) {
      return this.$api.deleteTrigger(triggerId);
    },

    parseOperator(op) {
      if (op === 'equal') {
        return '=';
      }
      if (op === 'above') {
        return '>';
      }
      if (op === 'below') {
        return '<';
      }
      return null;
    },

    showInfo(item) {
      this.triggerDetail = { ...item };
      this.$refs.triggerInfo.dialog = true;
    },
  },
};
</script>
