<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="700"
    :retain-focus="false"
  >
    <v-card :loading="loading">
      <v-card-title class="d-flex align-center">
        <v-icon class="mr-2">
          mdi-history
        </v-icon>
        <span>Logs: {{ triggerDetail.name }}</span>
      </v-card-title>

      <v-card-text class="mb-0 pa-0">
        <template v-if="!loading">
          <v-simple-table v-if="triggerLogs.length">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Log
                  </th>
                  <th class="text-left">
                    Timestamp
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(log, idx) in triggerLogs"
                  :key="idx"
                >
                  <td>{{ log.message }}</td>
                  <td>{{ $options.filters.formatDateTime(log.created_at) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-if="triggerLogs.length ==0"
            class="text-center my-5"
          >
            No logs data
          </div>
        </template>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { underscoreToSpace, isEmptyObject } from '@/utils/utils';

export default {
  name: 'TriggerInfo',
  props: {
    triggerDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      triggerLogs: [],
    };
  },

  computed: {
    // triggerDetailFiltered() {
    //   const keyToExclude = ['id', 'created_at', 'updated_at', 'organization_id', 'integration_id', 'last_uplink_at', 'app_server_id', 'latest_payload', 'energies_count', 'lorawan_device', 'commands'];

    //   return Object.entries(this.triggerDetail)
    //     .filter((d) => !keyToExclude.includes(d[0]))
    //     .map((item) => {
    //       if (item[0] === 'organization' || item[0] === 'integration') {
    //         item[1] = item[1] && item[1].name;
    //       }
    //       return {
    //         text: underscoreToSpace(item[0]),
    //         value: item[1] || '-',
    //       };
    //     });
    // },
  },

  watch: {
    dialog(state) {
      if (state) {
        this.getTriggerDetail(this.triggerDetail.id);
      }
    },
  },

  methods: {
    async getTriggerDetail(triggerId) {
      this.loading = true;
      try {
        const response = await this.$api.getTriggerDetail(triggerId);
        this.triggerLogs = response.data?.data?.logs;
      } catch (error) {
        console.error(error.response);
        this.$toast.error('Failed Get Triggers Logs');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
